export const mainStyle = {
    padding: "10px"
}

export const headingStyle = {
    marginTop: 0,
    marginBottom: "64px",
    flexGrow: 1,        // Allows the element to grow and take up available space
    width: "100%",      // Ensures the width is 100% of its container
    display: "flex",    // Enables Flexbox within the heading if needed
    justifyContent: "center", // Centers the content
    //textAlign: "center",      // Centers the text inside the element
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "100px"
}

export const headingAccentStyles = {
    color: "#663399",
}

export const paragraphStyles = {
    marginBottom: 48,
}

export const codeStyles = {
    color: "#8A6534",
    padding: 4,
    backgroundColor: "#FFF4DB",
    fontSize: "1.25rem",
    borderRadius: 4,
}

export const listStyles = {
    marginBottom: 96,
    paddingLeft: 0,
}

export const doclistStyles = {
    paddingLeft: 0,
}

export const listItemStyles = {
    fontWeight: 300,
    fontSize: 24,
    maxWidth: 560,
    marginBottom: 30,
}

export const linkStyle = {
    color: "#8954A8",
    fontWeight: "bold",
    fontSize: 16,
    verticalAlign: "5%",
}

export const docLinkStyle = {
    ...linkStyle,
    listStyleType: "none",
    display: `inline-block`,
    marginBottom: 24,
    marginRight: 12,
}

export const descriptionStyle = {
    color: "#232129",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 0,
    lineHeight: 1.25,
}

export const badgeStyle = {
    color: "#fff",
    backgroundColor: "#088413",
    border: "1px solid #088413",
    fontSize: 11,
    fontWeight: "bold",
    letterSpacing: 1,
    borderRadius: 4,
    padding: "4px 6px",
    display: "inline-block",
    position: "relative" as "relative",
    top: -2,
    marginLeft: 10,
    lineHeight: 1,
}