import * as React from "react";
import { createGlobalStyle } from "styled-components";
import { mainStyle } from "../styles/style";

const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100vh;
    margin: 0;
    color: #EDEDED;
    background-color: #1E1E1E; 
    padding: 0;
    font-family: "Adobe Caslon", "Times New Roman", Times, serif;
  }
`;

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <React.Fragment>
            <GlobalStyle />
            <main style={mainStyle}>
                {children}
            </main>
        </React.Fragment>
    );
};

export default Layout;